<template>
	<div id="navbar">
		<Nav />
		<VehicleStates />
		<ConnectionClock />
	</div>
</template>

<script>
	import VehicleStates from '@/components/RootNavBar/VehicleStates.vue';
	import Nav from '@/components/RootNavBar/Nav.vue';
	import ConnectionClock from '@/components/RootNavBar/ConnectionClock.vue';

	export default {
		name: 'RootNavBar',
		components: {
			VehicleStates,
			Nav,
			ConnectionClock,
		},
		// name: 'RootNavBar',
		// props: {
		//   msg: String
		// }
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	#navbar {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: left;
		background: #1a1f25;
		padding-top: 10px; // padding-top:10px;
		// padding-left: 15px;
		// padding-right: 15px;
		// &::before {
		//   content: "";
		//   height:10px;
		//   display: block;
		//   width: 100%;
		//   background: #1A1C48;
		//   position: absolute;
		//   left: 0;
		//   top: 0;
		//   border-bottom-left-radius: 50px;
		//   border-bottom-right-radius: 50px;
		//   border-bottom: 1px solid rgba(221, 221, 221, 0.7);
		// }
	}
</style>
