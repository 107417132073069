<template>
	<div id="nav">
		<router-link to="/navigation"><img src="@/assets/icons/40381.png" /></router-link>
		<router-link to="/docking"><img src="@/assets/icons/2910805.png" /></router-link>
		<router-link class="error" to="/vehicle"><img src="@/assets/icons/3768533.png" /></router-link>
		<router-link to="/procedures"><img src="@/assets/icons/2739776.png" /></router-link>
		<router-link to="/settings"><img src="@/assets/icons/1160356.png" /></router-link>
	</div>
</template>

<script>
	export default {
		name: 'NavComponent',
		// name: 'RootNavBar',
		// props: {
		//   msg: String
		// }
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	#nav {
		display: inline-block;
		width: 25%;
		position: relative;
		top: 5px;
		padding-left: 15px;
		a {
			font-weight: bold;
			color: #2c3e50;
			display: inline-block;
			img {
				height: 30px;
				padding: 10px;
				margin: 2px;
				filter: invert(100%) sepia(0%) saturate(7447%) hue-rotate(64deg) brightness(106%) contrast(97%);
			}
			&.error {
				img {
					// filter: invert(20%) sepia(68%) saturate(1931%) hue-rotate(331deg) brightness(93%) contrast(85%);
					filter: invert(74%) sepia(100%) saturate(7447%) hue-rotate(-370deg) brightness(80%) contrast(97%);
				}
			}
			&.router-link-active:after {
				content: '';
				width: 50px;
				border-bottom: solid 3px #ddd;
				position: absolute;
				bottom: 0;
				z-index: 1;
				margin-left: -53px;
				box-shadow: 0px 0px 6px 0px #ddd;
			}
		}
	}
</style>
