<template>
	<div class="stateSection">
		<div id="currentState"><label>Current State</label> Far Field Pointing Deorbit</div> <!--TODO: make dynamic -->
		<div id="activeState">Trunk Jettison and Deorbit<label>Burn Enabled</label></div>
		<div id="pointingMode"><label>Pointing Mode</label> Sun + GEO</div>
	</div>
</template>

<script>
	export default {
		name: 'VehicleStates',
		// name: 'RootNavBar',
		// props: {
		//   msg: String
		// }
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.stateSection {
		display: inline-block;
		color: #dddddd;
		position: relative;
		top: -10px;
		width: 48%;
		font-weight: bold;
		font-size: 0.9em;
		text-align: center;
		label {
			text-transform: uppercase;
			display: block;
			font-size: 11px;
			letter-spacing: 1px;
		}
		#currentState {
			display: inline-block;
			text-align: right;
			width: 30%;
			border-right: 2px solid rgba(221, 221, 221, 0.24);
			padding: 8px 10px;
		}
		#activeState {
			display: inline-block;
			width: 28%;
			padding: 10px;
			background: #45515f;
			border-radius: 5px;
			margin: 0 15px;
			label {
				font-size: 14px;
				text-transform: initial;
			}
		}
		#pointingMode {
			display: inline-block;
			text-align: left;
			width: 30%;
			border-left: 2px solid rgba(221, 221, 221, 0.24);
			padding: 8px 10px;
		}
	}
</style>
