import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/navigation',
	},
	{
		path: '/navigation',
		name: 'Navigation',
		component: () => import(/* webpackChunkName: "navigation" */ '../views/Navigation.vue'),
	},
	{
		path: '/docking',
		name: 'Docking',
		component: () => import(/* webpackChunkName: "docking" */ '../views/Docking.vue'),
	},
	{
		path: '/vehicle',
		component: () => import(/* webpackChunkName: "vehicle" */ '../views/Vehicle.vue'),
		children: [
			{
				path: '',
				name: 'Vehicle',
				redirect: '/vehicle/overview'},
			{
				path: '/vehicle/overview',
				component: () => import(/* webpackChunkName: "vehicleOverview" */ '../views/Vehicle/Overview.vue'),
			},
			{
				path: '/vehicle/life',
				component: () => import(/* webpackChunkName: "vehicleLife" */ '../views/Vehicle/Life.vue'),
			},
			{
				path: '/vehicle/comms',
				component: () => import(/* webpackChunkName: "vehicleComms" */ '../views/Vehicle/Comms.vue'),
			},
			{
				path: '/vehicle/prop',
				component: () => import(/* webpackChunkName: "vehicleProp" */ '../views/Vehicle/Prop.vue'),
			},
			{
				path: '/vehicle/mech',
				component: () => import(/* webpackChunkName: "vehicleMech" */ '../views/Vehicle/Mech.vue'),
			},
			{
				path: '/vehicle/power',
				component: () => import(/* webpackChunkName: "vehiclePower" */ '../views/Vehicle/Power.vue'),
			},
			{
				path: '/vehicle/avionics',
				component: () => import(/* webpackChunkName: "vehicleAvionics" */ '../views/Vehicle/Avionics.vue'),
			},
			{
				path: '/vehicle/gnc',
				component: () => import(/* webpackChunkName: "vehicleGnc" */ '../views/Vehicle/Gnc.vue'),
			},
			{
				path: '/vehicle/thermal',
				component: () => import(/* webpackChunkName: "vehicleThermal" */ '../views/Vehicle/Thermal.vue'),
			},
		],
	},
	{
		path: '/procedures',
		name: 'Procedures',
		component: () => import(/* webpackChunkName: "procedures" */ '../views/Procedures.vue'),
	},
	{
		path: '/settings',
		component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
		children: [
			{
				path: '',
				name: 'Settings',
				component: () => import(/* webpackChunkName: "settingsAudio" */ '../views/Settings/Audio.vue'),
			},
			{
				path: '/settings/cabin',
				component: () => import(/* webpackChunkName: "settingdCabin" */ '../views/Settings/Cabin.vue'),
			},
			{
				path: '/settings/video',
				component: () => import(/* webpackChunkName: "settingsVideo" */ '../views/Settings/Video.vue'),
			},
		],
	},
];

const router = new VueRouter({
	routes,
});

export default router;
