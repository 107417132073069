<template>
	<div id="app">
		<loading-blocker :loading="loading" :msg="loadingMsg"></loading-blocker>
		<div class="routerView">
			<transition name="fade" mode="out-in">
				<keep-alive>
					<router-view></router-view>
				</keep-alive>
			</transition>
		</div>
		<RootNavBar />
		{{/*  <crosshair />  */}}
	</div>
</template>

<script>
	import RootNavBar from '@/components/RootNavBar.vue';
	// import crosshair from '@/components/crosshair.vue';
	import '@/components_lit/loadingBlocker.js';
	export default {
		components: {
			RootNavBar,
			// crosshair,
		},
		data: () => ({ loading: false, loadingMsg: 'You wake up. The room is spinning very gently round your head. Or at least it would be if you could see it which you can\'t. It is pitch black.' }),
		mounted() {
			document.oncontextmenu = function () {
				return false;
			};
			document.addEventListener('dragover', (event) => event.preventDefault());
			document.addEventListener('drop', (event) => event.preventDefault());

			if(window.console && console.warn){
				let cw = console.warn;
				console.warn = function(){
					cw.apply(this, arguments)
				}
			}

			// console.clear();

			let stylesArray= [
				'background-image: url(https://cdn.wionews.com/sites/default/files/styles/large/public/2020/12/13/173805-9.jpg)',
				'background-size: cover',
				'color: #fff',
				'padding: 10px 20px',
				'line-height: 120px',
				'width : 70px',
				'height : 70px',
				'font-size: 60px',
				'font-family: monospace' ].join(';')
			console.log('%c   Occupy Mars!   ', stylesArray);





			//ULTIMATE QUESTION OF LIFE, THE UNIVERSE AND EVERYTHING
			let a = -8;
				a +=                 1    +       1 + 1;
				a +=             1 + 1    +   1 + 1 + 1 + 1;
				a +=         1 + 1 + 1    +   1 +     1 + 1;
				a +=     1 + 1     + 1    +          1 + 1;
				a +=     1         + 1    +         1 + 1;
				a +=     1 + 1 + 1 + 1    +        1 + 1;
				a +=     1 + 1 + 1 + 1    +       1 + 1;
				a +=             1 + 1    +      1 + 1;
				a +=             1 + 1    +     1 + 1;
				a +=             1 + 1    +   1 + 1 + 1 + 1;
			const question = 'the ultamate question of life, the universe and everything';
			console.log('The answer to '+question+' is... '+a);






		},
	};
</script>

<style lang="scss">
	* {
		font-family: 'Lato', Avenir, Helvetica, Arial, sans-serif;
	}
	body {
		background: #1a1f25 linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) no-repeat;
		overflow: hidden;
		// cursor: none;
		zoom: 58%; 
		// transform: scale(.5);
	}
	::-webkit-scrollbar {
		width: 10px;
		background: transparent;
	}
	::-webkit-scrollbar-thumb {
		background: #45515f;
	}
	#app {
		font-family: 'Lato', Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #ddd;
		.routerView {
			border-radius: 10px;
			border-bottom-left-radius: 40px;
			border-bottom-right-radius: 40px;
			border: 2px solid #45515f;
			background: #111111 linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) no-repeat;
			width: 99.8%;
			height: 93.8%;
			overflow: hidden;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
		}
		.settings,
		.vehicle {
			.routerView {
				margin: 10px;
				border-image: linear-gradient(to bottom, #45515f, #45515f00) 1 !important;
				width: 99% !important;
				height: 98% !important;
				border-bottom: 30px solid rgba(1, 1, 1, 1) !important;
			}
		}
		h1:first-of-type {
			font-size: 1.9rem;
			font-weight: bold;
			padding-top: 25px;
			padding-bottom: 0px;
			color: #fefefe;
			text-transform: uppercase;
			&:after {
				content: url('./assets/mocks/settingAudioGlow.png'); //
				display: inline-block;
				position: absolute;
				left: -300px;
				bottom: -100px;
				z-index: -1;
				opacity: 0.3;
				filter: saturate(60%);
			}
		}
		button {
			width: 80px;
			height: 80px;
			margin: 5px;
			margin-top: 15px;
			background: #1a1f25;
			color: #eaf1ff;
			border-radius: 5px;
			font-size: 35px;
			font-weight: bold;
			border: 3px solid #45515f;
			transition: all 0.2s ease;
			&:active {
				background: rgba(221, 221, 221, 1);
				color: #1a1f25;
			}
			img {
				height: 35px;
				margin-bottom: -5px;
				filter: invert(100%) sepia(0%) saturate(7447%) hue-rotate(64deg) brightness(93%) contrast(97%);
			}
			&.active {
				background: rgba(221, 221, 221, 1);
				color: #22273c;
				img {
					filter: invert(14%) sepia(66%) saturate(1303%) hue-rotate(205deg) brightness(83%) contrast(105%);
				}
			}
		}
	}
	.fade-enter-active,
	.fade-leave-active {
		transition-duration: 0.2s;
		transition-property: opacity;
		transition-timing-function: ease;
	}
	.fade-enter,
	.fade-leave-active {
		opacity: 0;
	}
	*,
	*::after,
	*::before {
		user-select: none;
		-webkit-user-drag: none;
		-webkit-app-region: no-drag;
		// cursor: none;
	}
	
		// .ep-circle {
		// 	-webkit-transform-origin: 29% 29% !important;
		// 	transform-origin: 29% 29% !important;
		// }
		
	// .ep-circle--container {
	// 	transform: translateX(33%) translateY(12%);
	// }
</style>
