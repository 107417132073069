<template>
	<div class="linkInfo">
		<div class="linkTimer">
			{{ timer1 }}
			<hr />
			{{ name1 }}
		</div>
		<div class="linkTimer">
			{{ timer2 }}
			<hr />
			{{ name2 }}
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LinkTimers',
		props: {
			timer1: String,
			timer2: String,
			name1: String,
			name2: String,
		},
		// name: 'RootNavBar',
		// props: {
		//   msg: String
		// }
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.linkInfo {
		display: inline-block;
		.linkTimer {
			display: inline-block;
			font-size: 12px;
			padding: 5px;
			line-height: 5px;
			position: relative;
			bottom: -10px;
			&:last-of-type {
				margin-right: 10px;
			}
		}
	}
</style>
