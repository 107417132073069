<template>
	<div class="linkInfo" v-bind:class="{ active: isActive === 'true' }">
		<div class="linkStatus">
			<img src="@/assets/icons/3726272.png" /><label>{{ name }}</label>
		</div>
		<div class="videoDownlink"><img src="@/assets/icons/1160041.png" /><br /><img src="@/assets/icons/4655094.png" /></div>
	</div>
</template>

<script>
	export default {
		name: 'StationLink',
		props: {
			isActive: String,
			name: String,
		},
		// name: 'RootNavBar',
		// props: {
		//   msg: String
		// }
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.linkInfo {
		display: inline-block;
		border-left: 2px solid rgba(221, 221, 221, 0.24);
		position: relative;
		top: 10px;
		&:last-of-type {
			border-right: 2px solid rgba(221, 221, 221, 0.24);
		}
		&.active {
			color: #3679c6;
			.linkStatus,
			.videoDownlink {
				opacity: 1;
				img {
					filter: invert(46%) sepia(100%) saturate(357%) hue-rotate(170deg) brightness(80%) contrast(96%);
					transform: rotate(0deg);
				}
			}
		}
		.linkStatus {
			display: inline-block;
			font-size: 13px;
			font-weight: bold;
			opacity: 0.6;
			img {
				height: 25px;
				padding: 7px;
				filter: invert(100%) sepia(0%) saturate(7447%) hue-rotate(64deg) brightness(106%) contrast(97%);
				position: relative;
				bottom: -5px;
				transform: rotate(90deg);
			}
			label {
				position: relative;
				top: -10px;
			}
		}
		.videoDownlink {
			display: inline-block;
			font-size: 8px;
			padding: 10px;
			margin-right: 5px;
			position: relative;
			top: -10px;
			opacity: 0.6;
			img {
				height: 10px;
				filter: invert(100%) sepia(0%) saturate(7447%) hue-rotate(64deg) brightness(106%) contrast(97%);
				position: relative;
				bottom: -8px;
			}
		}
	}
</style>
