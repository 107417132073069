<template>
	<div id="connectionClockSection">
		<div class="connectionInfo">
			<StationLink isActive="true" name="SPX" />
			<LinkTimers timer1="22:24" name1="GND" timer2="00:00" name2="TDRS" />
			<StationLink isActive="false" name="ISS" />
		</div>
		<MissionClock missionStartTime="2021-12-16T15:10:00" />
	</div>
</template>

<script>
	import StationLink from '@/components/RootNavBar/ConnectionClock/StationLink.vue';
	import LinkTimers from '@/components/RootNavBar/ConnectionClock/LinkTimers.vue';
	import MissionClock from '@/components/RootNavBar/ConnectionClock/MissionClock.vue';
	export default {
		name: 'ConnectionClock',
		components: {
			StationLink,
			LinkTimers,
			MissionClock,
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	#connectionClockSection {
		display: inline-block;
		width: 26%;
		position: relative;
		top: -15px;
		color: #dddddd;
		text-align: right;
		.connectionInfo {
			padding-right: 10px;
			display: inline-block;
		}
	}
</style>
