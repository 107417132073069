//dragonData: helper used for both udp data as well as mockData

let config, mission, payload, flightComputer;
const fcMode = 'mock'; //mock,udp

config = {
	commanderSeat: 2,
	pilotSeat: 3,
};

mission = {
	//mission phases
	178: {
		titleShort: 'Deport Burn',
		titleLong: 'Deport & Burn',
		subPhase: {
			// 1: {}
		},
	},
	179: {
		titleShort: 'Deorbit Coast',
		titleLong: 'Coast to Trunk Jettision',
		subPhase: {
			1: {
				title: 'Crew Interrupt Conditions',
				steps: {
					1: {
						description: "30' sustained altitude error",
						pointing: 'FAR FIELD POINTING',
					},
					2: {
						description: "600'm/s altitude rate",
						pointing: 'FAR FIELD POINTING',
					},
				},
			},
			2: {
				title: 'Crew Deorbit Preparation',
				steps: {
					1: {
						description: "30' sustained altitude error",
						pointing: 'FAR FIELD POINTING',
					},
					2: {
						description: "600'm/s altitude rate",
						pointing: 'FAR FIELD POINTING',
					},
				},
			},
			3: {
				title:
					'1 Monitor slow to free-flight altitude (Sun+GEO pointing)<br>2 After SpaceX GO for deorbit, verify entry is enabled:<br> ENTRY Enabled TRUE FALSE<br>3 After entry is enabled, Dragon transitions to Claw Separation',
				titleType: 'multi',
				steps: {
					1: {
						description: "30' sustained altitude error",
						pointing: 'FAR FIELD POINTING',
					},
					2: {
						description: "600'm/s altitude rate",
						pointing: 'FAR FIELD POINTING',
					},
				},
			},
			4: {
				title: 'Crew Deorbit Preparation',
				steps: {
					1: {
						description: "30' sustained altitude error",
						pointing: 'FAR FIELD POINTING',
					},
					2: {
						description: "600'm/s altitude rate",
						pointing: 'FAR FIELD POINTING',
					},
				},
			},
		},
	},
	180: {
		titleShort: 'Deorbit Coast',
		titleLong: 'Claw Seperation',
		subPhase: {
			// 1: {}
		},
	},
	181: {
		titleShort: 'Deorbit Coast',
		titleLong: 'Procedure',
		subPhase: {
			// 1: {}
		},
	},
	182: {
		titleShort: 'Seperate Trunk',
		titleLong: 'Manual Chute Deploy',
		subPhase: {
			// 1: {}
		},
	},
};

//sample captured flightString
// {"SEN":{"LIF":{"CAB":{"TEM":2.4,"PSI":14.01,"PPO":2.68,"CO2":0.16}},"POW":{"PU1":{"AMP":1.7,"ENG":94},"PU2":{"AMP":0.2,"ENG":100}},"PRO":{"DO":{"F":{"CAP":900,"USA":791.1},"O":{"CAP":1400,"USA":1308}},"O1":{"F":{"CAP":100,"USA":67.76},"O":{"CAP":120,"USA":111.3}},"O2":{"F":{"CAP":100,"USA":67.76},"O":{"CAP":120,"USA":111.3}}},"COO":{"CLA":{"TEM":27.53},"CLB":{"TEM":20.02}},"GNC":{"POS":{"VTE":{"PIT":101.3,"ROL":30.4,"YAW":45.4},"VTT":{"PIT":2.9,"ROL":5.9,"YAW":-3.3},"DFT":{"PIT":-1.4,"ROL":-0.9,"YAW":1.8}},"ORB":{"CAO":{"VEL":7.69,"ALT":393.3,"APO":416.2,"PER":379.4,"INC":52.62}},"FRA":{},"VEL":{"ACC":0.21}}},"PHA":{"CAP":{"PHA":179,"PST":null,"SDT":null},"CRP":{"PHA":179}},"VEH":{"ERR":{"OVE":1,"LIF":0,"COM":0,"PRO":0,"MEC":0,"POW":0,"AVI":0,"GNC":0,"THE":0}},"SET":{"ERR":{"AUD":0,"CAB":0,"VID":0},"AUD":{"CAB_MAIN_VOL":89,"CAB_GND_VOL":2,"CAB_GND_TXRX":1,"CAB_AUX_VOL":2,"CAB_AUX_TXRX":1,"CAB_ICOM_VOL":0,"CAB_ALERT_VOL":25,"CAB_VOX_VOL":0,"S1_GND_VOL":0,"S1_GND_TXRX":0,"S1_AUX_VOL":0,"S1_AUX_TXRX":0,"S1_ICOM_VOL":0,"S1_ALERT_VOL":0,"S1_VOX_VOL":0,"S2_GND_VOL":9,"S2_GND_TXRX":1,"S2_AUX_VOL":0,"S2_AUX_TXRX":0,"S2_ICOM_VOL":5,"S2_ALERT_VOL":50,"S2_VOX_VOL":0,"S3_GND_VOL":8,"S3_GND_TXRX":1,"S3_AUX_VOL":0,"S3_AUX_TXRX":0,"S3_ICOM_VOL":4,"S3_ALERT_VOL":50,"S3_VOX_VOL":0,"S4_GND_VOL":0,"S4_GND_TXRX":0,"S4_AUX_VOL":0,"S4_AUX_TXRX":0,"S4_ICOM_VOL":0,"S4_ALERT_VOL":0,"S4_VOX_VOL":0},"CAB":{"LGT_AMBI":1,"LGT_FLOR":1,"LGT_CEIL":1,"LGT_NIGT":0,"LGT_S1DO":1,"LGT_S2DO":1,"LGT_S3DO":1,"LGT_S4DO":1,"LGT_CRGO":0,"LGT_HTCH":1,"LGT_TRNK":0,"LGT_EXTE":1,"DSP1_LUX":1,"DSP2_LUX":0,"DSP3_LUX":0},"VID":{"TX_CAM":1,"DISP_CAM":2,"REMO_CAM":0}}}

function randomize(start, wetdry = 1) {
	let random = Math.floor(Math.random() * wetdry +2);
	let plusOrMinus = Math.random() < 0.5 ? -wetdry : 1;
	random = random * plusOrMinus;
	return start + (random*.1);
}

function flightString() {
	return {
		leaderFC: '1',
		flightString: {
			//supplied by consensus function; only work with a single flightString
			SEN: {
				//sensors
				LIF: {
					//life support
					CAB: {
						//cabin
						TEM: randomize(6.32), //temp
						PSI: randomize(14.01), //cabin pressure
						PPO: randomize(2.68), //PPO2
						CO2: randomize(0.16), //CO2
					},
				},
				POW: {
					//power
					PU1: {
						//power unit 1
						AMP: randomize(1.7), //AMPS
						ENG: randomize(94, 10.5), //energy percent
					},
					PU2: {
						//power unit 2 2
						AMP: randomize(0.2), //AMPS
						ENG: randomize(100, 10.5), //energy percent
					},
				},
				PRO: {
					//propellant
					DO: {
						//deorbit
						F: {
							//fuel
							CAP: 900, //capacity
							USA: randomize(791.1, 10.5), //usable
						},
						O: {
							//oxidizer
							CAP: 1400, //capacity
							USA: randomize(1308, 10.5), //usable
						},
					},
					O1: {
						//orbit 1
						F: {
							//fuel
							CAP: 100, //capacity
							USA: randomize(67.76, 10.5), //usable
						},
						O: {
							//oxidizer
							CAP: 120, //capacity
							USA: randomize(111.3, 10.5), //usable
						},
					},
					O2: {
						//orbit 2
						F: {
							//fuel
							CAP: 100, //capacity
							USA: randomize(67.76, 10.5), //usable
						},
						O: {
							//oxidezer
							CAP: 120, //capacity
							USA: randomize(111.3, 10.5), //usable
						},
					},
				},
				COO: {
					//cooling
					CLA: {
						//loop a
						TEM: randomize(27.53), //temp
					},
					CLB: {
						//loop b
						TEM: randomize(20.02), //temp
					},
				},
				GNC: {
					//GNC
					POS: {
						//position
						VTE: {
							//vehicle to earth frame
							PIT: randomize(101.3), //pitch
							ROL: randomize(30.4), //roll
							YAW: randomize(45.4), //yaw
						},
						VTT: {
							//vehicle to target frame
							PIT: randomize(2.9), //pitch
							ROL: randomize(5.9), //roll
							YAW: randomize(-3.3), //yaw
						},
						DFT: {
							//difference from target
							PIT: randomize(-1.4), //pitch
							ROL: randomize(-0.9), //roll
							YAW: randomize(1.8), //yaw
						},
					},
					ORB: {
						//orbit
						CAO: {
							//current active orbit
							VEL: randomize(7.69), //inertial velocity
							ALT: randomize(393.3), //altitude
							APO: randomize(416.2), //apogee
							PER: randomize(379.4), //perigee
							INC: randomize(52.62), //inclination
						},
					},
					FRA: {
						//frame
					},
					VEL: {
						//velocity
						ACC: randomize(0.21), //velocity g-force
					},
				},
			},
			PHA: {
				//phase
				CAP: {
					//current active phase
					PHA: 179, //phase index
					PST: null, //phase start time
					SDT: null, //splashdowntime?
				},
				CRP: {
					//current running phase
					PHA: 179, //phase index
				},
			},
			VEH: {
				//vehicle
				ERR: {
					//errors
					OVE: 1, //overview
					LIF: 0, //life
					COM: 0, //comms
					PRO: 0, //propellant
					MEC: 0, //mechanical
					POW: 0, //power
					AVI: 0, //avionics
					GNC: 0, //GNC
					THE: 0, //Thermal
				},
			},
			SET: {
				//settings
				ERR: {
					//errors
					AUD: 0, //audio error
					CAB: 0, //cabin error
					VID: 0, //video error
				},
				AUD: {
					//audio
					CAB_MAIN_VOL: 89,
					CAB_GND_VOL: 2,
					CAB_GND_TXRX: 1,
					CAB_AUX_VOL: 2,
					CAB_AUX_TXRX: 1,
					CAB_ICOM_VOL: 0,
					CAB_ALERT_VOL: 25,
					CAB_VOX_VOL: 0,
					S1_GND_VOL: 0,
					S1_GND_TXRX: 0,
					S1_AUX_VOL: 0,
					S1_AUX_TXRX: 0,
					S1_ICOM_VOL: 0,
					S1_ALERT_VOL: 0,
					S1_VOX_VOL: 0,
					S2_GND_VOL: 9,
					S2_GND_TXRX: 1,
					S2_AUX_VOL: 0,
					S2_AUX_TXRX: 0,
					S2_ICOM_VOL: 5,
					S2_ALERT_VOL: 50,
					S2_VOX_VOL: 0,
					S3_GND_VOL: 8,
					S3_GND_TXRX: 1,
					S3_AUX_VOL: 0,
					S3_AUX_TXRX: 0,
					S3_ICOM_VOL: 4,
					S3_ALERT_VOL: 50,
					S3_VOX_VOL: 0,
					S4_GND_VOL: 0,
					S4_GND_TXRX: 0,
					S4_AUX_VOL: 0,
					S4_AUX_TXRX: 0,
					S4_ICOM_VOL: 0,
					S4_ALERT_VOL: 0,
					S4_VOX_VOL: 0,
				},
				CAB: {
					//cabin
					LGT_AMBI: 1, //ambiance light
					LGT_FLOR: 1, //floor light
					LGT_CEIL: 1, //ceiling light
					LGT_NIGT: 0, //night light
					LGT_S1DO: 1, //seat 1 downlight
					LGT_S2DO: 1, //seat 2 downlight
					LGT_S3DO: 1, //seat 3 downlight
					LGT_S4DO: 1, //seat 4 downlight
					LGT_CRGO: 0, //cargo light
					LGT_HTCH: 1, //hatch light
					LGT_TRNK: 0, //trunk light
					LGT_EXTE: 1, //exterior light
					DSP1_LUX: 1, //display 1 lux (brightness from 0-1)
					DSP2_LUX: 0, //display 2 lux (brightness from 0-1)
					DSP3_LUX: 0, //display 3 lux (brightness from 0-1)
				},
				VID: {
					TX_CAM: 1, //transmitting camera
					DISP_CAM: 2, //displayed camera
					REMO_CAM: 0, //remote camera connection status (falcon9 while attached)
				},
			},
		},
	};
}

let flightStringData = flightString();

payload = {};

function udpCmd(cmd) {
	console.log(cmd);
}

flightComputer = {
	toggleSetting(setting, parent) {
		let currentVal, newVal;
		currentVal = flightStringData.flightString.SET[parent][setting];
		switch (currentVal) {
			case 1:
				newVal = 0;
				break;
			case 0:
				newVal = 1;
				break;
			default:
				console.error('Setting Not Found');
		}
		switch (fcMode) {
			case 'udp':
				udpCmd('CMD_TGL_' + parent + '-' + setting);
				break;
			case 'mock':
				flightStringData.flightString.SET[parent][setting] = newVal;
				break;
		}
	},
	setSetting(setting, parent, value) {
		switch (fcMode) {
			case 'udp':
				udpCmd('CMD_SET_' + parent + '-' + setting + ':' + value);
				break;
			case 'mock':
				flightStringData.flightString.SET[parent][setting] = value;
				break;
		}
	},
	adjustVOL(setting, parent, method, min, max, step) {
		// var udpCommand = "CMD_ADJ_"+parent+'-'+setting+':'+method+step;
		// alert(udpCommand);
		let currentVal = flightStringData.flightString.SET[parent][setting];
		switch (method) {
			case '+':
				if (currentVal < max) {
					switch (fcMode) {
						case 'udp':
							udpCmd('CMD_ADJ_' + parent + '-' + setting + ':' + method + step);
							break;
						case 'mock':
							flightStringData.flightString.SET[parent][setting] = currentVal + step;
							break;
					}
				}
				break;
			case '-':
				if (currentVal > min) {
					switch (fcMode) {
						case 'udp':
							udpCmd('CMD_ADJ_' + parent + '-' + setting + ':' + method + step);
							break;
						case 'mock':
							flightStringData.flightString.SET[parent][setting] = currentVal - step;
							break;
					}
				}
				break;
		}
	},
};

function fetchData() {
	// flightStringData = flightString();
	return flightStringData;
}

module.exports = {
	config: config,
	mission: mission,
	payload: payload,
	data: flightString(),
	flightComputer: flightComputer,
	fetchData: fetchData,
};
