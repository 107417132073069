import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'typeface-lato/index.css';
import VueEllipseProgress from 'vue-ellipse-progress';
import '@google/model-viewer';
import Dragon from './helpers/dragonData';
// import ipc from 'electron';

//TODO: integrate webAssembly
// import addModule from './wasm/add.wasm';
// const extractModule = async (module) => {
//   const { instance } = await module();
//   return instance.exports;
// };
// const add = extractModule(addModule);
// console.log(add(1, 2));

Vue.config.productionTip = false;
Vue.use(VueEllipseProgress);

//TODO: add test cases
//TODO: cleanup code
//TODO: move to components
//TODO: convert components to Lit
//TODO: rename files
//TODO: cleanup images
//TODO: fix all inline css
//TODO: fix any use of !important
//TODO: cleanup comments
//TODO: mock UDP proxy
//TODO: error logging

Vue.prototype.$dragonConfig = Dragon.config;
Vue.prototype.$dragonMission = Dragon.mission;
// Vue.prototype.$dragonData = Dragon.fetchData();//Dragon.data;// Dragon.fetchData();//
Vue.prototype.$flightComputer = Dragon.flightComputer;

new Vue({
	router,
	render: (h) => h(App),
	data() {
		return {
			status: true,
			data: [],
			interval: null,
		};
	},
	methods: {
		async reNew() {
			// Fetch data efficiently
			try {
				// const response = await axios.get('https://randomuser.me/api/?results=5');
				// this.data = response.data.results;
				Vue.prototype.$dragonData = await Dragon.fetchData();
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		},
	},
	mounted() {
		this.reNew();
		// ipc.on('DRAGON_DATA', (event, message) => {
		// 	Vue.prototype.$dragonData = message;
		// });
		this.interval = setInterval(this.reNew, 7000); // Adjusted to 7000ms as per comment
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
}).$mount('#app');

