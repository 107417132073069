<template>
	<div id="missionClock">{{ days }}/{{ hours }}.{{ minutes }}.{{ seconds }}.{{ decaseconds }}</div>
</template>

<script>
	export default {
		name: 'MissionClock',
		props: {
			missionStartTime: String,
		},
		data() {
			return {
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
				decaseconds: 0,
				startTime: null,
				animationFrameId: null,
			};
		},
		methods: {
			updateTime() {
				const now = Date.now();
				const difference = now - this.startTime;

				this.days = Math.floor(difference / (1000 * 60 * 60 * 24)) + 1;
				this.hours = this.formatDigit(Math.floor((difference / (1000 * 60 * 60)) % 24));
				this.minutes = this.formatDigit(Math.floor((difference / 1000 / 60) % 60));
				this.seconds = this.formatDigit(Math.floor((difference / 1000) % 60));
				this.decaseconds = this.formatDigit(Math.floor((difference % 1000) / 100)).slice(1);

				this.animationFrameId = requestAnimationFrame(this.updateTime);
			},
			formatDigit(digit) {
				return digit < 10 ? '0' + digit : '' + digit;
			},
		},
		mounted() {
			this.startTime = new Date(this.missionStartTime).getTime();
			this.updateTime();
		},
		beforeDestroy() {
			if (this.animationFrameId) {
				cancelAnimationFrame(this.animationFrameId);
			}
		},
	};
</script>

<style scoped>
	#missionClock {
		font-size: 18px;
		font-weight: 600;
		padding: 10px;
		display: inline-block;
		position: relative;
		top: 0px;
	}
</style>
